import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('core', ['companyName']),
    seoTitle() {
      let title = 'Favoriten'

      return this.replaceTags(title)
    },
    seoDescription() {
      let description = 'Favoriten ★ Ihre persönliche Auswahl ★ <companyName>'

      return this.replaceTags(description)
    }
  },
  methods: {
    replaceTags: function(text) {
      text = text.replace('<companyName>', this.companyName)

      return text
    }
  }
}
