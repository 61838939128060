<template>
  <div class="am-favorites">
    <div class="am-favorites__inner">
      <amui-compact-toolbar
        v-if="appMode === 'widget'"
        class="am-favorites__compact-toolbar"
      >
        <amui-compact-link
          label="Alle Fahrzeuge"
          icon="tune-simple"
          @click.native="onClickSearch"
        />
      </amui-compact-toolbar>

      <amui-headline html-tag="h1" looks-like="h5" class="amui-mt-24"
        >Favoriten</amui-headline
      >
      <template v-if="!isLoading && mappedVehicleList.length">
        <div class="amui-mt-16 amui-mt-24:m amui-mt-32:xl">
          <div class="am-favorites__results">
            <am-vehicle-tile-extended
              v-for="(vehicle, i) of mappedVehicleList"
              :key="i"
              :sources="vehicle.sources"
              :fallback-source="vehicle.fallbackSource"
              :efficiency-class="vehicle.efficiencyClass"
              :efficiencyText="vehicle.efficiencyText"
              :title="vehicle.title"
              :isFavorite="vehicle.isFavorite"
              :rate="vehicle.rate"
              :make="vehicle.make"
              :am-model-year="vehicle.amModelYear"
              :model="vehicle.model"
              :usage-type="vehicle.usageType"
              :availability-in-weeks="vehicle.availabilityInWeeks"
              :availability-label="vehicle.availabilityLabel"
              :customer-highlights="vehicle.customerHighlights"
              :features="vehicle.features"
              :vehicle-class="vehicle.vehicleClass"
              :vehicle-url="vehicle.url"
              :price="vehicle.price"
              :price-tax-info="vehicle.priceTaxInfo"
              :crossedOutPrice="vehicle.crossedOutPrice"
              :crossedOutPriceReferenceNumber="
                vehicle.crossedOutPriceReferenceNumber
              "
              :sold="vehicle.sold"
              @view="onViewVehicle(vehicle.id)"
              @favour="onFavoriteChange(vehicle.id)"
            >
            </am-vehicle-tile-extended>
          </div>
        </div>
        <am-legal-foot-note class="amui-mb-56:m amui-mb-64 amui-mt-32" />
      </template>
      <div
        class="amui-mt-16 amui-mt-24:m amui-mt-32:xl"
        v-if="!isLoading && mappedVehicleList.length === 0"
      >
        <div>
          Ihre Favoritenliste ist leer.
        </div>
        <amui-button
          type="primary"
          icon="tune-simple"
          htmlTag="button"
          class="amui-w-full amui-w-auto:m amui-mt-24 amui-mt-40:m amui-mt-56:xl amui-mb-64 amui-mb-56:m"
          label="Alle Fahrzeuge"
          @click.native="onClickSearch"
        ></amui-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { getId as getVehicleId } from './../../../../utils/vehicle'
import vehicleCardHelperMixin from '@/app/mixins/vehicle-card-helper.mixin'
import AmLegalFootNote from '@/app/components/legal-foot-note/legal-foot-note'
import SeoMixin from './mixins/seo.mixin'
import AmVehicleTileExtended from '@/shared/components/list/vehicle-tile-extended/vehicle-tile-extended'
import { AmuiHeadline } from '@/../ui/components/headline'
import { AmuiButton } from '@/../ui/components/button'
import { AmuiCompactToolbar } from '@/../ui/components/compact-toolbar'
import { AmuiCompactLink } from '@/../ui/components/compact-link'
import vehicleTrackingMixin from '@/app/mixins/vehicle-tracking.mixin'

import { getGTMTracker } from '@/app/tracking/gtm.js'

export default {
  name: 'FavoritesView',

  mixins: [vehicleCardHelperMixin, SeoMixin, vehicleTrackingMixin],

  components: {
    AmVehicleTileExtended,
    AmLegalFootNote,
    AmuiHeadline,
    AmuiCompactToolbar,
    AmuiButton,
    AmuiCompactLink
  },

  metaInfo() {
    return this
      ? {
          title: this.seoTitle,
          titleTemplate: '%s',
          meta: [{ name: 'description', content: this.seoDescription }]
        }
      : null
  },

  data() {
    return {
      isLoading: true,
      gtm: null
    }
  },

  computed: {
    ...mapState('favorites', ['rawVehicleData', 'vehicles']),
    ...mapState('core', ['appMode']),
    mappedVehicleList() {
      return this.vehicles
        .map(vehicle => {
          const foundRawVehicleData = this.rawVehicleData.find(
            raw => vehicle.id === getVehicleId(raw)
          )

          let vehicleData = []

          if (foundRawVehicleData !== undefined) {
            vehicleData = this.getMappedVehicleCardData(foundRawVehicleData)
          } else {
            vehicleData =
              vehicle.data && this.getMappedVehicleCardData(vehicle.data)
          }

          let sources = []
          let fallbackSource = null

          if (vehicleData.media.length > 0) {
            const media = vehicleData.media[0]

            sources = [
              {
                media: '(max-width: 375px)',
                srcset: [
                  this.getSourceByMediaAndWidth(media, 480),
                  this.getSourceByMediaAndWidth(media, 960) + ' 2x'
                ].join(', ')
              },
              {
                media: '(max-width: 619px)',
                srcset: [
                  this.getSourceByMediaAndWidth(media, 600),
                  this.getSourceByMediaAndWidth(media, 1200) + ' 2x'
                ].join(', ')
              },
              {
                media: '(min-width: 620px)',
                srcset: [
                  this.getSourceByMediaAndWidth(media, 480),
                  this.getSourceByMediaAndWidth(media, 960) + ' 2x'
                ].join(', ')
              }
            ]

            fallbackSource = sources[0].srcset
          }

          return Object.assign({}, vehicleData, {
            sources,
            fallbackSource: fallbackSource
          })
        })
        .filter(v => v !== undefined) // when switching accounts for example, or obsolet stored cars
    }
  },

  created() {
    this.fetchVehicles().then(() => {
      this.isLoading = false
    })
  },

  mounted() {
    this.gtm = getGTMTracker()
  },

  methods: {
    ...mapActions('favorites', [
      'fetchVehicles',
      'toggleFavoriteStateByRawVehicleData',
      'removeVehicleById'
    ]),
    ...mapActions('vehicleRequest', ['setRequestedVehicle', 'showRequest']),
    getSourceByMediaAndWidth(media, width) {
      let src = null

      if (media.type === 'cpo') {
        const splittedSegments = media.normal.split('/')
        const filename = splittedSegments.pop()
        src = splittedSegments.join('/') + '/tr:w-' + width + '/' + filename
      } else {
        src = media.normal
      }

      return src
    },
    getAutouncleVehicleUrl(vehicle) {
      let url = vehicle.url

      if (
        vehicle.autounclePriceRatingIntegration &&
        vehicle.autounclePriceRatingIntegration.settings?.vehicleUrl
      ) {
        url = vehicle.autounclePriceRatingIntegration.settings?.vehicleUrl.replace(
          '{mobileAdId}',
          vehicle.mobileAdId
        )
      }

      return url
    },

    onViewVehicle(vehicleId) {
      this.trackSelectItem(
        this.rawVehicleData.find(raw => vehicleId === getVehicleId(raw))
      )

      this.$router.push({
        name: 'detail',
        params: {
          id: vehicleId
        }
      })
    },

    onRequestVehicle(vehicleId) {
      const rawVehicleData = this.rawVehicleData.find(raw => {
        return getVehicleId(raw) === vehicleId
      })

      this.setRequestedVehicle({
        vehicle: rawVehicleData
      })
      this.showRequest()
    },

    onFavoriteChange(vehicleId) {
      let foundRawVehicleData

      if (Array.isArray(this.rawVehicleData)) {
        foundRawVehicleData = this.rawVehicleData.find(rawVehicleData => {
          return getVehicleId(rawVehicleData) === vehicleId
        })
      }

      if (foundRawVehicleData !== undefined) {
        this.toggleFavoriteStateByRawVehicleData(foundRawVehicleData)
      } else {
        this.removeVehicleById(vehicleId)
      }
    },

    onClickSearch() {
      this.$router.push({ name: 'app' })
    },

    trackSelectItem(item) {
      const payload = {
        ecommerce: {
          items: [this.getVehicleTrackingObject(item)]
        }
      }

      this.gtm.trackEvent({
        name: 'select_item',
        payload
      })
    }
  }
}
</script>
